import { Box, Container, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';
import { RouteComponentProps } from 'react-router-dom';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import { ContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { ErrorPageContent } from 'shared/src/error-page-content/error-page-content';
import { NotFoundPageContent } from 'shared/src/error-page-content/not-found-page-content';
import { Quote } from 'shared/src/typography/quote';

import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { usePatientsStoryBySlug } from 'pages/patient-story/queries.contentful';
import { PatientStoryBySlug } from 'pages/patient-story/types/queries.contentful.generated';
import heroBannerBackground from 'pages/patients/components/hero-banner/patients-banner-pattern.svg';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${heroBannerBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  },
  heroImageContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(-1),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(15),
    },
  },
  image: {
    width: '100%',
    borderRadius: 'inherit',
    verticalAlign: 'middle',
  },
}));

type PatientStory = DeepExtractTypeSkipArrays<
  PatientStoryBySlug,
  ['patientsStoryCollection', 'items']
>;

export const PatientStoryPage = ({ story }: { story: PatientStory }) => {
  di(ConditionSelectPanel, PageBase);

  const classes = useStyles();

  return (
    <PageBase windowTitle={`Patient Stories - ${story.name}`}>
      <Box paddingTop={{ xs: 4, sm: 12 }} className={classes.background}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Box marginRight={{ xs: 0, sm: 5 }}>
                <Typography variant="h2">Meet {story.name}</Typography>
                <Box paddingTop={2} paddingBottom={4}>
                  <Typography color="textSecondary" component="div">
                    {story.summary && <RichText document={story.summary} />}
                  </Typography>
                </Box>
                <ConditionSelectPanel />
              </Box>
            </Grid>
            {story.patientImage && (
              <Grid item xs={6} sm={6}>
                <Box
                  maxHeight={{ xs: '40vw', sm: 488 }}
                  marginTop={{ xs: 3, sm: 0 }}
                  overflow="hidden"
                >
                  <ContentfulImage
                    maxWidth="sm"
                    className={classes.image}
                    image={story.patientImage}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      <Container>
        <Box marginTop={{ xs: 7, sm: 10 }} marginBottom={{ xs: 7, sm: 10 }}>
          <Grid container alignItems="center" justifyContent="space-around" direction="row-reverse">
            <Grid item xs={12} sm={5}>
              <Quote>{story.quote}</Quote>
              <Box marginTop={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  <b>{story.name},</b> {story.age} — {story.condition}
                </Typography>
              </Box>
            </Grid>
            {story.quoteImage && (
              <Grid item xs={6} sm={4}>
                <Box marginTop={{ xs: 5, sm: 0 }}>
                  <ContentfulImage maxWidth="sm" image={story.quoteImage} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Divider />
        <Box marginTop={{ xs: 7, sm: 10 }} marginBottom={{ xs: 7, sm: 10 }}>
          <Grid container alignItems="flex-start" justifyContent="center">
            <Grid item xs={12}>
              <Box marginBottom={{ xs: 3, sm: 4 }}>
                <Typography variant="h3">{story.name}’s story</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box marginRight={{ xs: 0, sm: 8 }} marginBottom={{ xs: 4, sm: 0 }}>
                {story.story && <RichText document={story.story} />}
              </Box>
            </Grid>
            {story.patientPortrait && (
              <Grid item xs={12} sm={6}>
                <Paper elevation={0}>
                  <ContentfulImage
                    maxWidth="sm"
                    image={story.patientPortrait}
                    className={classes.image}
                  />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box marginTop={{ xs: 5, sm: 7 }}>
                <ConditionSelectPanel />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </PageBase>
  );
};

export const PatientStoryPageRoute = ({ match }: RouteComponentProps<{ slug: string }>) => {
  const { data, loading, error } = usePatientsStoryBySlug({
    variables: {
      slug: `story-${match.params.slug}`,
    },
  });

  if (loading) {
    return <LoadingPage windowTitle="Loading" />;
  }

  if (!data || error) {
    return (
      <ErrorPageContent
        error={error || new Error(`No patient story found with slug ${match.params.slug}`)}
      />
    );
  }

  if (
    data.patientsStoryCollection?.items.length === 0 ||
    data.patientsStoryCollection?.items[0] == null
  ) {
    return <NotFoundPageContent />;
  }

  const story = data.patientsStoryCollection?.items[0];

  return <PatientStoryPage story={story} />;
};
