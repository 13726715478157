import { memo } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';

import { assertExists } from 'shared/src/types/assert-exists';

import { ArticleMenuCta } from 'components/article-sticky-menu/article-menu-cta/article-menu-cta';
import { ErrorPage } from 'components/error/error-page';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';

import { ArticleCard } from './components/article-card/article-card';
import { useSanitizedKeywords } from './components/use-sanitized-keywords/use-sanitized-keywords';
import { BlogPostCollectionItem, FeaturedBlogPost, useFindBlogPosts } from './queries.contentful';

interface ContentProps {
  featuredBlog?: FeaturedBlogPost;
  blogs: readonly BlogPostCollectionItem[];
}

const BlogPostCard = memo(
  ({ post, featured }: { post: Omit<BlogPostCollectionItem, 'sys'>; featured?: boolean }) => {
    const { keywords: maybeKeywords, heroImage, title, category, date, slug } = post;
    const keywords = useSanitizedKeywords(maybeKeywords);
    const url = `/blog/${assertExists(slug, 'post requires slug')}`;

    return (
      <ArticleCard
        featured={featured}
        mainImage={heroImage || undefined}
        title={title || 'Untitled'}
        category={category || undefined}
        date={date || undefined}
        keywords={keywords}
        url={url}
      />
    );
  }
);

const useStyles = makeStyles(theme => ({
  cardContainer: {
    marginBottom: 80,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
    },
  },
}));

export const BlogIndexContent = memo(({ blogs, featuredBlog }: ContentProps) => {
  di(PageBase);

  const classNames = useStyles();

  return (
    <PageBase windowTitle="Blog">
      <Container>
        <Box marginTop={{ xs: 3, md: 7 }} marginBottom={{ sm: 7, md: 7 }}>
          <Typography variant="h2">The HealthMatch Blog</Typography>
          <Box marginTop={2} marginBottom={{ xs: 0, md: featuredBlog ? 6 : 0 }}>
            <Typography gutterBottom={!!featuredBlog} color="textSecondary">
              Looking for everything HealthMatch and clinical trials? We’ve got you sorted. Written
              by our team, experts and patients – just for you.
            </Typography>
          </Box>
          {featuredBlog && (
            <Box marginBottom={{ xs: 6 }} marginTop={{ xs: 4 }}>
              <Grid container>
                <Grid item md={8} xs={12}>
                  <BlogPostCard featured post={featuredBlog} />
                </Grid>
                <Grid item xs={4}>
                  <Box marginLeft={7} display={{ xs: 'none', md: 'block' }}>
                    <ArticleMenuCta conditionName={undefined} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Grid container spacing={3}>
          {blogs.map(post => (
            <Grid item sm={4} xs={12} key={post.sys.id} className={classNames.cardContainer}>
              <BlogPostCard post={post} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageBase>
  );
});

export const BlogIndexPage = () => {
  const { data, error } = useFindBlogPosts({
    offset: 0,
    limit: 200,
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!data) {
    return <LoadingPage windowTitle="Blog" />;
  }

  return <BlogIndexContent blogs={data.items} featuredBlog={data.featuredBlog} />;
};
