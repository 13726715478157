import { Component, ComponentType, ErrorInfo, ReactNode } from 'react';

import { ErrorPageContentLite } from 'shared/src/error-page-content/error-page-content';

interface Logger {
  error(message: string, messageContext?: object): void;
}

export type ErrorBoundaryProps = {
  logger?: Logger;
  ErrorComponent?: ComponentType<{ onError?: () => void; error: Error }>;
  errorKey?: string;
};

type ErrorBoundaryState = { hasError: boolean; error?: Error };

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, error: undefined };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidUpdate(props: ErrorBoundaryProps): void {
    if (this.props?.errorKey !== props.errorKey) {
      this.resetError();
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.props.logger?.error(error.message, {
      ...error,
      ...errorInfo,
      type: 'react',
    });
  }

  resetError: () => void = () => this.setState({ hasError: false });

  render(): ReactNode {
    const { children, ErrorComponent = ErrorPageContentLite } = this.props;

    if (this.state.hasError) {
      return (
        <ErrorComponent
          onError={this.resetError}
          error={this.state.error || new Error('Unknown error')}
        />
      );
    }

    return children;
  }
}
