import { memo } from 'react';

import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import { di } from 'react-magnetic-di/macro';
import { useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { helmetJsonLdProp } from 'react-schemaorg';
import { SocialMediaPosting } from 'schema-dts';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

import healthmatchLogo from 'shared/src/assets/healthmatch-logo-primary.svg';
import { AccentuatedContentfulImage } from 'shared/src/contentful-image/contentful-image';
import { RichText } from 'shared/src/contentful-text/rich-text-renderer';
import { assertExists } from 'shared/src/types/assert-exists';

import { ConditionSelectPanel } from 'mats/src/components/condition-select-panel/condition-select-panel';

import { KeywordsLine } from 'components/article-info/article-info';
import { ArticleMenuCta } from 'components/article-sticky-menu/article-menu-cta/article-menu-cta';
import { ReviewedBy, WrittenBy } from 'components/content-meta/author-credit/author-credit';
import { ContentMetaContainer } from 'components/content-meta/content-meta';
import { UpdatedAt } from 'components/content-meta/date-meta/date-meta';
import { getRedirectIfExists } from 'components/contentful-redirect/contentful-redirect';
import { ErrorPage } from 'components/error/error-page';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';
import { CanonicalUrl, OgArticleType, OgImage } from 'components/seo/meta-data-fragments';
import { useSanitizedKeywords } from 'pages/blog-index/components/use-sanitized-keywords/use-sanitized-keywords';
import { EmbeddedQuestionnaire } from 'pages/condition-article/embedded-questionnaire/embedded-questionnaire';

import { BlogContentThemeProvider, BlogHeading, BlogSubHeading } from './blog-content-theme';
import { useFindBlogPost } from './queries.contentful';
import { FindBlogPost } from './types/queries.contentful.generated';

const heroImageMaxWidth = 720;

const useStyles = makeStyles(theme => ({
  heroImage: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  byLine: {
    fontWeight: 700,
    fontSize: 18,
  },
  shareContainer: {
    // Fix horizontal and vertical scroll for small breakpoints
    // See https://material-ui.com/components/grid/#negative-margin
    overflow: 'hidden',
    color: theme.palette.text.secondary,
    '&& a:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const BlogPost = memo(
  ({
    entry,
  }: {
    entry: DeepExtractTypeSkipArrays<FindBlogPost, ['blogPostCollection', 'items']>;
  }) => {
    di(ConditionSelectPanel);

    const classNames = useStyles();
    const { pathname } = useLocation();
    const { authorProfile, heroImage, medicalReviewer } = entry;
    const keywords = useSanitizedKeywords(entry.keywords);
    const title = assertExists(entry.title, 'title required for blog page');

    const conditionName = 'your medical condition';
    const trialsCTA = <ArticleMenuCta conditionName={conditionName} />;

    return (
      <>
        <Helmet
          script={[
            helmetJsonLdProp<SocialMediaPosting>({
              '@context': 'https://schema.org',
              '@type': 'SocialMediaPosting',
              headline: entry.titleMeta ?? entry.title ?? '',
              description: entry.descriptionMeta ?? '',
              // "createdAt" = first published, "updatedAt" = latest publish, see "sys properties" table at:
              // https://www.contentful.com/developers/docs/references/content-management-api/#/introduction/collection-resources-and-pagination
              datePublished: entry.sys.firstPublishedAt ?? undefined,
              dateModified: entry.sys.publishedAt ?? undefined,
              url: `${import.meta.env.VITE_HMIO_URL}/blog/${entry.slug ?? ''}`,
              ...(heroImage?.url ? { image: heroImage.url } : {}),
              publisher: {
                '@type': 'Organization',
                name: 'HealthMatch',
                logo: {
                  '@type': 'ImageObject',
                  url: healthmatchLogo,
                },
              },
              ...(authorProfile != null
                ? {
                    author: {
                      '@type': 'Person',
                      name: authorProfile?.name ?? undefined,
                    },
                  }
                : {}),
            }),
          ]}
        />
        <CanonicalUrl url={`${import.meta.env.VITE_HMIO_URL}${pathname}`} />
        {heroImage && <OgImage image={heroImage} />}
        <OgArticleType
          published={entry.sys.firstPublishedAt}
          modified={entry.sys.publishedAt}
          author={authorProfile?.name}
        />
        <Container>
          <Box marginTop={{ xs: 3, md: 5 }}>
            <Grid container>
              <Grid item md={10} xs={12}>
                <BlogHeading>{title}</BlogHeading>
                {keywords && <KeywordsLine keywords={keywords} gutterBottom />}
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item md={8} xs={12}>
              <ContentMetaContainer marginY={{ xs: 3, sm: 5 }} maxWidth={`${heroImageMaxWidth}px`}>
                {entry.sys.publishedAt && <UpdatedAt date={entry.sys.publishedAt} />}
                {medicalReviewer && <ReviewedBy author={medicalReviewer} />}
                {authorProfile && <WrittenBy author={authorProfile} />}
              </ContentMetaContainer>
              {heroImage && (
                <AccentuatedContentfulImage
                  maxWidth={heroImageMaxWidth}
                  image={heroImage}
                  borderRadius="md"
                  containerClassName={classNames.heroImage}
                  lazy={false}
                />
              )}
              {entry.subtitle && <BlogSubHeading gutterBottom>{entry.subtitle}</BlogSubHeading>}
              {entry.content && (
                <BlogContentThemeProvider>
                  <RichText document={entry.content} />
                </BlogContentThemeProvider>
              )}
            </Grid>
            <Grid item xs={4}>
              <Box marginLeft={7} display={{ xs: 'none', md: 'block' }}>
                {trialsCTA}
              </Box>
            </Grid>
            <EmbeddedQuestionnaire conditionName="a medical condition" />
          </Grid>
        </Container>
      </>
    );
  }
);

export const BlogPostRoute = memo(({ match }: RouteComponentProps<{ slug: string }>) => {
  const { data, loading, error } = useFindBlogPost({ variables: { slug: match.params.slug } });

  if (loading) {
    return <LoadingPage windowTitle="Loading Blog Post" />;
  }

  const redirect = getRedirectIfExists(data?.redirectCollection?.items[0]);

  if (redirect) {
    return redirect;
  }

  if (error || !data?.blogPostCollection?.items?.[0]) {
    return (
      <ErrorPage
        error={error || new Error(`Could not find blog post for slug ${match.params.slug}`)}
      />
    );
  }

  return (
    <PageBase
      windowTitle={assertExists(data.blogPostCollection.items[0].title, 'Blog post title')}
      descriptionMeta={data.blogPostCollection.items[0].descriptionMeta ?? undefined}
    >
      <BlogPost entry={data.blogPostCollection.items[0]} />
    </PageBase>
  );
});
