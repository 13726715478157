import { FC } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { di } from 'react-magnetic-di/macro';

import { ErrorPageContent } from 'shared/src/error-page-content/error-page-content';
import { ButtonLink } from 'shared/src/link/link';
import { LoadingPageContent } from 'shared/src/loading-page-content/loading-page-content';
import { collectBy } from 'shared/src/util/collect-by';

import { TrialCardPublic } from 'mats/src/components/trial-card-public/trial-card-public';

import { BrowseCardPublic } from './browse-card-public/browse-card-public';
import { usePublicTrialsForCondition } from './queries';

type TrialListPublicProps = {
  conditionName: string;
  conditionId: ConditionID;
};

const useStyles = makeStyles(({ typography, spacing }) => ({
  exploreTitle: {
    fontSize: typography.pxToRem(35),
    paddingBottom: spacing(5),
  },
}));

export const TrialListPublic: FC<TrialListPublicProps> = ({ conditionId, conditionName }) => {
  di(TrialCardPublic, BrowseCardPublic, usePublicTrialsForCondition);

  const classes = useStyles();

  const {
    data: trialsForConditionData,
    error: trialsForConditionError,
    loading: trialsForConditionLoading,
  } = usePublicTrialsForCondition({ variables: { conditionId } });

  const loading = trialsForConditionLoading;
  const error = trialsForConditionError;

  if (loading) {
    return <LoadingPageContent />;
  }

  if (error) {
    return <ErrorPageContent error={error} />;
  }

  const conditionNameLowerCase = conditionName.toLocaleLowerCase();
  if (trialsForConditionData && trialsForConditionData.searchTrials.length > 0) {
    return (
      <>
        <Typography variant="h3" gutterBottom className={classes.exploreTitle}>
          Explore clinical trials for {conditionNameLowerCase}
        </Typography>
        <Grid container spacing={3}>
          {trialsForConditionData.searchTrials.slice(0, 8).map(trial => (
            <Grid item xs={12} md={6} lg={4} key={trial.idOpaque}>
              <TrialCardPublic
                trialIdOpaque={trial.idOpaque}
                trialTitle={trial.title}
                locations={collectBy(
                  trial.trialSites.map(ts => ts.location),
                  l => l.countryCode,
                  l => l.state
                )}
                recruitingStatus={trial.patientRecruitingStatus}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={6} lg={4}>
            <BrowseCardPublic conditionId={conditionId} />
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Typography variant="h3" align="center">
        Explore clinical trials for {conditionNameLowerCase}
      </Typography>
      <Box paddingBottom={2}>
        <Typography variant="h5" align="center" gutterBottom>
          Get matched with new {conditionName} trials
        </Typography>
      </Box>

      <Box paddingBottom={4}>
        <Typography align="center">
          We’ll keep searching until we find the right match for you. Create your profile and get
          alerts when new {conditionNameLowerCase} trials become available.
        </Typography>
      </Box>
      <Box justifyContent="center" display="flex">
        <ButtonLink color="primary" variant="outlined" to={`/signup/${conditionId}`}>
          Create a profile
        </ButtonLink>
      </Box>
    </>
  );
};
