import { FC } from 'react';

import { Box, Container, Grid, Typography, useTheme } from '@material-ui/core';
import { di } from 'react-magnetic-di/macro';

import { ErrorPage } from 'components/error/error-page';
import { Directory } from 'components/guides-directory/directory/directory';
import { useDirectoryItemsGroupedByTitle } from 'components/guides-directory/directory/use-directory-items-grouped-by-title';
import { LoadingPage } from 'components/loading-page/loading-page';
import { PageBase } from 'components/page-base/page-base';

import { useGuideDirectoryConditionCategories } from './queries.contentful';

type GuidesDirectoryProps = {};

export const GuidesDirectory: FC<GuidesDirectoryProps> = () => {
  di(useGuideDirectoryConditionCategories, PageBase, useDirectoryItemsGroupedByTitle);

  const theme = useTheme();

  const { data, error, loading } = useGuideDirectoryConditionCategories();

  const directoryData = useDirectoryItemsGroupedByTitle(
    data?.conditionCategoryCollection?.items,
    i => i.condition,
    i => i.slug,
    [data?.conditionCategoryCollection?.items]
  );

  if (loading) {
    return <LoadingPage windowTitle="Guide directory" />;
  }

  if (error || !directoryData) {
    return (
      <ErrorPage error={error || new Error('Could not find any data for the guide directory')} />
    );
  }

  return (
    <PageBase windowTitle="Guide directory">
      <Container>
        <Box paddingTop={5}>
          <Typography variant="h1">Directory</Typography>
        </Box>
        <Box paddingY={3}>
          <Typography variant="body2" color="textSecondary">
            View our full library of condition guides
          </Typography>
        </Box>
        <Grid container>
          <Directory inputs={directoryData} legendTitle="Browse by condition name" />
        </Grid>
      </Container>
      <Box height={theme.spacing(10)} />
    </PageBase>
  );
};

export const GuidesDirectoryRoute: FC = () => <GuidesDirectory />;
