import { FC } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// eslint complains about date-fns/typings.d.ts being imported multiple times when importing locale files
// eslint-disable-next-line import/no-duplicates
import enAULocale from 'date-fns/locale/en-AU';
// eslint-disable-next-line import/no-duplicates
import enCALocale from 'date-fns/locale/en-CA';
// eslint-disable-next-line import/no-duplicates
import enGBLocale from 'date-fns/locale/en-GB';
// eslint-disable-next-line import/no-duplicates
import enNZLocale from 'date-fns/locale/en-NZ';
// eslint-disable-next-line import/no-duplicates
import enUSLocale from 'date-fns/locale/en-US';
// eslint-disable-next-line import/no-duplicates
import esLocale from 'date-fns/locale/es';
// eslint-disable-next-line import/no-duplicates
import frLocale from 'date-fns/locale/fr';
// eslint-disable-next-line import/no-duplicates

import { useLocale } from 'shared/src/locale-provider/locale-provider';
import { SupportedLocale } from 'shared/src/locales/locales';

// en-HK locale is missing from date-fns.
// en-HK date formatting is the same as en-GB (in particular they both start the calendar week on Monday), so use en-GB as a template.
const enHKLocale: Locale = {
  ...enGBLocale,
  code: 'en-HK',
};

const dateFnsLocaleMap: { [key in SupportedLocale]: Locale } = {
  en: enUSLocale, // fallback to en-US
  'en-AU': enAULocale,
  'en-US': enUSLocale,
  'en-HK': enHKLocale,
  'en-NZ': enNZLocale,
  'en-GB': enGBLocale,
  'en-CA': enCALocale,
  'fr-CA': frLocale,
  'es-US': esLocale,
};

type Props = {
  localeOverride?: SupportedLocale;
};

export const LocalisedMuiPickersUtilsProvider: FC<Props> = ({ children, localeOverride }) => {
  const locale = useLocale();

  const dateFnsLocale = dateFnsLocaleMap[localeOverride ?? locale];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};
