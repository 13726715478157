import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import es from './es_US/translation.json';
import fr from './fr_CA/translation.json';

i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  debug: false, // set to true to see console logs
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    fr: {
      translation: fr,
    },
  },
  fallbackLng: {
    'es-US': ['en-US', 'en'],
    'fr-CA': ['en-US', 'en'],
    default: ['en'],
  },
});

export const i18n = i18next;
