import { ComponentProps, ComponentType } from 'react';

import { SvgIcon } from '@material-ui/core';

import {
  AcceptedStatus,
  AcceptedStatusPath,
  AppointmentCalendar,
  Archive,
  ArrowDown,
  ArrowDownRounded,
  ArrowLeft,
  ArrowRight,
  ArrowTopRight,
  ArrowUp,
  BarChart,
  BarChartInputComplete,
  Bell,
  BookmarkedOpenBook,
  Calendar,
  Calendar2,
  Calendar3,
  Chat,
  ChatColor,
  CheckMarkRounded,
  Clinic,
  ClinicalTrials,
  Clock,
  Close,
  ColorIdentity,
  Compensation,
  Compensation2,
  Copy,
  DeniedStatus,
  DeniedStatusPath,
  Doctor,
  Documents,
  DollarCircle,
  Download,
  Edit,
  EmailRounded,
  EnvelopeColor,
  EnvelopeIcon as Envelope,
  ExternalLinkImage,
  FacebookCircle,
  Glasses,
  GreenHeart,
  GreenPill,
  HealthMatch,
  HeartStethoscope,
  Help,
  Hospital,
  Hourglass,
  Identity,
  InfoCircle,
  InfoCircleOutlined,
  LanguageImage,
  LanguageOutlined,
  LinkChain,
  Login,
  MagnifyingGlass,
  MedicalCrossShield,
  MedicalQuestionnaire,
  Microscope,
  Money,
  Myself,
  OpenBook,
  OpenInNewTab,
  Overnight,
  PageInfo,
  Patient,
  PatientApplication,
  PdfFile,
  PendingStatus,
  PendingStatusPath,
  Person,
  Phone,
  PhoneRounded,
  Pill,
  PillGrey,
  Pin,
  PinOutlined,
  Plus,
  Process,
  Profile,
  ProfileCheck,
  ProfileCross,
  PurplePill,
  Ribbon,
  Scroll,
  Search,
  Settings,
  ShareDoc,
  SmsColored,
  SmsRounded,
  Snooze,
  SoftMatching,
  Sparkles,
  StatusChanged,
  StemmedArrowRight,
  Stethoscope,
  StickyNote,
  Telehealth,
  Telehealth2,
  TextInput,
  TextInputComplete,
  Thermometer,
  TrashOutlined,
  TrialPhase,
  TrialType,
  Upload,
  UsersAndPeople,
  Warning,
  WavingHand,
} from './icon-images';

const makeIcon =
  (component: ComponentType, viewBox: string, transform?: string) =>
  (props: ComponentProps<typeof SvgIcon>) =>
    <SvgIcon component={component} style={{ transform }} viewBox={viewBox} {...props} />;

// When adding new icons, find the `viewBox` attribute in the SVG asset to use here
export const AcceptedStatusIcon = makeIcon(AcceptedStatus, '0 0 50 50');
export const AcceptedStatusPathIcon = makeIcon(AcceptedStatusPath, '-20 -40 96.57 125.37');
export const AppointmentCalendarIcon = makeIcon(AppointmentCalendar, '0 0 14 14');
export const ArchiveIcon = makeIcon(Archive, '0 0 24 24');
export const ArrowDownIcon = makeIcon(ArrowDown, '0 0 200 200');
export const ArrowDownRoundedIcon = makeIcon(ArrowDownRounded, '0 0 20 20');
export const ArrowUpRoundedIcon = makeIcon(ArrowDownRounded, '0 0 20 20', 'scaleY(-1)');
export const ArrowLeftIcon = makeIcon(ArrowLeft, '0 0 24 24');
export const ArrowRightIcon = makeIcon(ArrowRight, '0 0 24 24');
export const ArrowTopRightIcon = makeIcon(ArrowTopRight, '0 0 13.486 12.324');
export const ArrowUpIcon = makeIcon(ArrowUp, '0 0 24 24');
export const BarChartIcon = makeIcon(BarChart, '0 0 26 24.07');
export const BarChartInputCompleteIcon = makeIcon(BarChartInputComplete, '0 0 26 24.07');
export const BookmarkedOpenBookIcon = makeIcon(BookmarkedOpenBook, '0 0 24 24');
export const Calendar2Icon = makeIcon(Calendar2, '1 1.5 13.8 13');
export const CalendarIcon = makeIcon(Calendar, '0 0 245.29 245.26');
export const Calendar3Icon = makeIcon(Calendar3, '0 0 24 24');
export const ChatColorIcon = makeIcon(ChatColor, '0 0 158.07 145.85');
export const ChatIcon = makeIcon(Chat, '0 0 26.1 24.08');
export const CheckMarkRoundedIcon = makeIcon(CheckMarkRounded, '0 0 24 25');
export const ClinicalTrialsIcon = makeIcon(ClinicalTrials, '0 0 39.34 40');
export const ClinicIcon = makeIcon(Clinic, '0 0 24 24');
export const ClockIcon = makeIcon(Clock, '0 0 14 14');
export const CloseIcon = makeIcon(Close, '0 0 20 20');
export const ColorIdentityIcon = makeIcon(ColorIdentity, '0 0 24 24');
export const CompensationIcon = makeIcon(Compensation, '0 0 14 14');
export const Compensation2Icon = makeIcon(Compensation2, '0 0 24 24');
export const CopyIcon = makeIcon(Copy, '0 0 24 24');
export const DeniedStatusIcon = makeIcon(DeniedStatus, '0 0 50 50');
export const DeniedStatusPathIcon = makeIcon(DeniedStatusPath, '-10 -10 60 60');
export const DoctorIcon = makeIcon(Doctor, '0 0 31.91 40.32');
export const DocumentsIcon = makeIcon(Documents, '0 0 22.71 24.01');
export const DollarCircleIcon = makeIcon(DollarCircle, '0 0 24.03 24.03');

export const DownloadIcon = makeIcon(Download, '0 0 24 24');
export const EditIcon = makeIcon(Edit, '0 0 20 20');
export const EmailRoundedIcon = makeIcon(EmailRounded, '0 0 24 24');
export const EnvelopeColorIcon = makeIcon(EnvelopeColor, '0 0 24 24');
export const EnvelopeIcon = makeIcon(Envelope, '0 0 140.14 92.5');
export const ExternalLinkIcon = makeIcon(ExternalLinkImage, '0 0 17 17');
export const FacebookCircleIcon = makeIcon(FacebookCircle, '0 0 11.55 11.49');
export const FileIcon = makeIcon(PdfFile, '0 0 24 24');
export const GlassesIcon = makeIcon(Glasses, '0 0 38.98 39.8');
export const GreenHeartIcon = makeIcon(GreenHeart, '0 0 24 22.405');
export const GreenPillIcon = makeIcon(GreenPill, '0 0 72.98 74.01');
export const HealthMatchIcon = makeIcon(HealthMatch, '0 0 64 64');
export const HeartStethoscopeIcon = makeIcon(HeartStethoscope, '0 0 24 24');
export const HelpIcon = makeIcon(Help, '0 0 19.5 19.5');
export const HospitalIcon = makeIcon(Hospital, '0 0 24 24');
export const HourglassIcon = makeIcon(Hourglass, '0 0 14 14');
export const IdentityIcon = makeIcon(Identity, '0 0 12.85 11.39');
export const InfoCircleIcon = makeIcon(InfoCircle, '0 0 144.45 144.45');
export const InfoCircleOutlinedIcon = makeIcon(InfoCircleOutlined, '0 0 16 16');
export const LanguageIcon = makeIcon(LanguageImage, '0 0 16 17');
export const LanguageOutlinedIcon = makeIcon(LanguageOutlined, '0 0 24 22');
export const LinkChainIcon = makeIcon(LinkChain, '0 0 12 12');
export const LoginIcon = makeIcon(Login, '0 0 32 32');
export const MagnifyingGlassIcon = makeIcon(MagnifyingGlass, '0 2 24 24');
export const MedicalCrossShieldIcon = makeIcon(MedicalCrossShield, '0 0 20.54 23.91');
export const MedicalQuestionnaireIcon = makeIcon(MedicalQuestionnaire, '0 0 24 24');
export const MicroscopeIcon = makeIcon(Microscope, '0 0 19.43 23.95');
export const MoneyIcon = makeIcon(Money, '0 0 50 50');
export const MyselfIcon = makeIcon(Myself, '0 0 39.34 40');
export const OpenBookIcon = makeIcon(OpenBook, '0 0 24 25');
export const OpenInNewTabIcon = makeIcon(OpenInNewTab, '0 0 10 9');
export const OvernightIcon = makeIcon(Overnight, '0 0 14.2 14.2');
export const PageInfoIcon = makeIcon(PageInfo, '0 0 169.75 153.87');
export const PatientApplicationIcon = makeIcon(PatientApplication, '0 0 18 24');
export const PatientIcon = makeIcon(Patient, '0 0 39.34 40');
export const PendingStatusIcon = makeIcon(PendingStatus, '0 0 50 50');
export const PendingStatusPathIcon = makeIcon(PendingStatusPath, '-10 -10 60 60');
export const PersonIcon = makeIcon(Person, '0 0 16 17');
export const PhoneIcon = makeIcon(Phone, '0 0 24 24');
export const PhoneRoundedIcon = makeIcon(PhoneRounded, '0 0 24 24');
export const PillGreyIcon = makeIcon(PillGrey, '0 0 72.98 74.01');
export const PillIcon = makeIcon(Pill, '0 0 23.03 40.38');
export const PinIcon = makeIcon(Pin, '0 0 36.1 49.47');
export const PinOutlinedIcon = makeIcon(PinOutlined, '1 1 21 23');
export const PlusIcon = makeIcon(Plus, '0 0 238.33 238.33');
export const ProcessIcon = makeIcon(Process, '0 0 24 24');
export const ProfileIcon = makeIcon(Profile, '0 0 24 24');
export const ProfileCheckIcon = makeIcon(ProfileCheck, '0 0 24 24');
export const ProfileCrossIcon = makeIcon(ProfileCross, '0 0 24 24');
export const PurplePillIcon = makeIcon(PurplePill, '0 0 72.98 74.01');
export const RibbonIcon = makeIcon(Ribbon, '0 0 18.14 24.06');
export const ScrollIcon = makeIcon(Scroll, '-2 -2 20 20');
export const SearchIcon = makeIcon(Search, '0 0 30 30.01');
export const SettingsIcon = makeIcon(Settings, '0 0 24 24');
export const ShareDocIcon = makeIcon(ShareDoc, '0 0 24 24');
export const SmsRoundedIcon = makeIcon(SmsRounded, '0 0 24 24');
export const SmsColoredIcon = makeIcon(SmsColored, '0 0 24 24');
export const SnoozeIcon = makeIcon(Snooze, '0 0 24 25');
export const SoftMatchingIcon = makeIcon(SoftMatching, '0 0 160.03 141.71');
export const SparklesIcon = makeIcon(Sparkles, '0 0 24 24');
export const StatusChangedIcon = makeIcon(StatusChanged, '0 0 24 24');
export const StemmedArrowRightIcon = makeIcon(StemmedArrowRight, '0 0 24 24');
export const StethoscopeIcon = makeIcon(Stethoscope, '0 0 38.39 43.26');
export const StickyNoteIcon = makeIcon(StickyNote, '0 0 24 24');
export const TelehealthIcon = makeIcon(Telehealth, '0 0 110 86.49');
export const Telehealth2Icon = makeIcon(Telehealth2, '0 0 24 24');
export const TextInputCompleteIcon = makeIcon(TextInputComplete, '0 0 24 24');
export const TextInputIcon = makeIcon(TextInput, '0 0 24 24');
export const ThermometerIcon = makeIcon(Thermometer, '0 0 40.75 40.75');
export const TrashOutlinedIcon = makeIcon(TrashOutlined, '0 0 24 24');
export const TrialPhaseIcon = makeIcon(TrialPhase, '0 0 14.04 13');
export const TrialTypeIcon = makeIcon(TrialType, '0 0 12.96 14.02');
export const UploadIcon = makeIcon(Upload, '0 0 24 24');
export const UsersAndPeopleIcon = makeIcon(UsersAndPeople, '0 0 24 24');
export const WarningIcon = makeIcon(Warning, '0 0 17.32 15.4');
export const WavingHandIcon = makeIcon(WavingHand, '0 0 24 24');
export const BellIcon = makeIcon(Bell, '0 0 12 16');
